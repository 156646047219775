import { bindActionCreators, configureStore } from '@reduxjs/toolkit';

import { Extra } from '../types';
import { memberThunk, memberSlice, uiThunk, uiSlice } from './slices';
import { rootReducer, RootState } from './rootReducer';
import { accountDataThunk } from './thunks/accountData';

export const createStore = (
  extraArgument: Extra,
  initialState: Partial<RootState>,
) =>
  configureStore({
    reducer: rootReducer,
    preloadedState: initialState,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          isSerializable: () => true,
        },
        thunk: { extraArgument },
      }),
  });

export type Store = ReturnType<typeof createStore>;

export const getActionHandlers = (store: Store) => ({
  memberHandlers: bindActionCreators(
    { ...memberThunk, ...memberSlice.actions },
    store.dispatch,
  ),
  uiHandlers: bindActionCreators(
    { ...uiThunk, ...uiSlice.actions },
    store.dispatch,
  ),
  accountDataHandlers: bindActionCreators(
    { ...accountDataThunk },
    store.dispatch,
  ),
});
