import { FlowApi } from '../../types';

export const withLocaleHeadersFactory =
  (flowAPI: FlowApi) =>
  <T>(requestOptions: T) => {
    const { multilingual } = flowAPI.environment;
    if (multilingual?.isEnabled) {
      const currentLanguage = multilingual.siteLanguages.find(
        (lang) => lang.languageCode === multilingual.currentLanguage,
      );
      if (currentLanguage) {
        const wixLinguistHeader = {
          'x-wix-linguist': `${currentLanguage.languageCode}|${currentLanguage.locale}|${currentLanguage.isPrimaryLanguage}|${flowAPI.environment.appDefinitionId}`,
        };

        return {
          ...requestOptions,
          headers: { ...requestOptions, ...wixLinguistHeader },
        };
      }
    }

    return requestOptions;
  };
