import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  PrivacyStatusStatus,
  Image,
} from '@wix/ambassador-members-ng-api/types';

import { AccountDataProps, Member, ThunkApiConfig } from '../../../types';
import * as accountApi from '../../../server/account.api';
import { unblockMember as unblockMemberApi } from '../../../server/account.api';
import { uploadImage } from './services/uploadImage';
import { WIX_STATIC_MEDIA } from '../../../constants/urls';

type Picture = {
  width?: number;
  height?: number;
  file_name?: string;
  original_fileName?: string;
};

const hasMemberAvatarChanged = (currentMember: Member, initialMember: Member) =>
  currentMember.profile.photo?.url !== initialMember.profile.photo?.url;

export const makeProfilePrivate = createAsyncThunk<void, void, ThunkApiConfig>(
  'member/makeProfilePrivate',
  async (_, { extra }) => {
    const { flowAPI, profileInfoChangeSubject } = extra;
    await flowAPI.httpClient.request(accountApi.leaveCommunity());
    profileInfoChangeSubject?.notifyObservers({
      profilePrivacy: PrivacyStatusStatus.PRIVATE,
    });
  },
);

export const makeProfilePublic = createAsyncThunk<void, void, ThunkApiConfig>(
  'member/makeProfilePublic',
  async (_, { extra }) => {
    const { flowAPI, profileInfoChangeSubject } = extra;
    await flowAPI.httpClient.request(accountApi.joinCommunity());
    profileInfoChangeSubject?.notifyObservers({
      profilePrivacy: PrivacyStatusStatus.PUBLIC,
    });
  },
);

export const updateSlug = createAsyncThunk<string, string, ThunkApiConfig>(
  'member/updateSlug',
  async (newSlug, { rejectWithValue, extra }) => {
    const { flowAPI } = extra;

    try {
      const { data } = await flowAPI.httpClient.request(
        accountApi.updateSlug(newSlug),
      );
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const unblockMember = createAsyncThunk<string, string, ThunkApiConfig>(
  'members/unblockMember',
  async (memberId, { extra, rejectWithValue }) => {
    const { flowAPI } = extra;
    try {
      await flowAPI.httpClient.request(unblockMemberApi(memberId));
      return memberId;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const maybeUpdateAvatar = createAsyncThunk<
  { avatarPhoto: Image | undefined; mediaPlatformPicture?: Picture },
  Pick<AccountDataProps, 'currentMember'>,
  ThunkApiConfig
>(
  'member/updateAvatar',
  async ({ currentMember }, { extra: { flowAPI }, getState }) => {
    const state = getState();
    const initialMember = state.member;
    const defaultReturnData = {
      avatarPhoto: currentMember.profile.photo,
    };

    if (
      !currentMember.profile.photo?.url ||
      !hasMemberAvatarChanged(currentMember, initialMember)
    ) {
      return defaultReturnData;
    }

    const mediaPlatformPicture = await uploadImage(
      flowAPI,
      currentMember.profile.photo?.id,
      currentMember.profile.photo?.url,
    );

    if (!mediaPlatformPicture?.file_name) {
      return defaultReturnData;
    }

    const avatarPhoto = {
      url: `https://${WIX_STATIC_MEDIA}${mediaPlatformPicture.file_name}`,
      height:
        mediaPlatformPicture?.height ?? initialMember.profile.photo?.height,
      width: mediaPlatformPicture?.width ?? initialMember.profile.photo?.width,
      id: mediaPlatformPicture.file_name,
    };

    return {
      avatarPhoto,
      mediaPlatformPicture,
    };
  },
);
